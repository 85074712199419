<template>
  <button :disabled="disabled" class="whitespace-nowrap bg-gradient-to-r from-gray-200 to-gray-300 border border-gray-400 h-6 md:h-8 rounded-full text-center text-sm px-1.5 focus:border-blue-400 focus:outline-none" :class="classes" @click="$emit('click', $event)">
    <svg v-if="icon === 'notes'" xmlns="http://www.w3.org/2000/svg" class="h-4 w-3 md:h-5 md:w-5" viewBox="0 0 20 20" fill="currentColor">
      <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
      <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd" />
    </svg>
    <svg v-else-if="icon === 'more'" xmlns="http://www.w3.org/2000/svg" class="h-4 w-3 md:h-5 md:w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" clip-rule="evenodd" />
    </svg>
    <svg v-else-if="icon === 'play'" xmlns="http://www.w3.org/2000/svg" class="h-4 w-3 md:h-5 md:w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
    </svg>
    <svg v-else-if="icon === 'pause'" xmlns="http://www.w3.org/2000/svg" class="h-4 w-3 md:h-5 md:w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
    </svg>
    <svg v-else-if="icon === 'activate'" xmlns="http://www.w3.org/2000/svg" class="h-4 w-3 md:h-5 md:w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" clip-rule="evenodd" />
    </svg>
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  computed: {
    classes() {
      const rv = [];
      if (this.disabled) {
        rv.push("text-gray-500 cursor-default");
      } else {
        rv.push("text-gray-600 hover:border-gray-500 hover:shadow");
      }
      return rv;
    },
  },
});
</script>
