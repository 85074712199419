<template>
  <p :class="classes">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "error",
    },
  },
  computed: {
    classes() {
      const rv = [
        "border",
        "font-semibold",
        "mb-4",
        "py-2",
        "px-4",
        "rounded",
        "text-sm",
        "leading-snug",
      ];
      if (this.type == "error") {
        rv.push("bg-red-200", "border-red-300", "text-red-800");
      } else if (this.type == "success") {
        rv.push("bg-green-200", "border-green-300", "text-green-800");
      } else {
        rv.push("bg-gray-200", "border-gray-300", "text-gray-800");
      }
      return rv;
    },
  },
};
</script>
