<template>
  <div>
    <input type="text" :class="classes" v-bind="$attrs" :value="modelValue" autocomplete="off" @input="$emit('update:modelValue', $event.target.value)">
    <datalist v-if="options.length" :id="$attrs.list">
      <option v-for="(option, index) in options" :key="index" :value="option" />
    </datalist>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Number, String],
      default: undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  computed: {
    classes() {
      const classes = [
        "w-full",
        "bg-white",
        "border",
        "border-gray-300",
        "rounded",
        "py-0.5",
        "px-1",
        "md:py-1",
        "md:px-2",
        "focus:ring-0",
        "text-sm",
        "md:text-base",
        "placeholder-gray-400",
      ];
      if (this.$attrs.disabled) {
        classes.push("bg-gray-200 text-gray-500");
      } else {
        classes.push(
          "hover:border-gray-400",
          "text-gray-700",
          "focus:border-blue-400"
        );
      }
      return classes;
    },
  },
};
</script>
