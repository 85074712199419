<template>
  <div class="md:rounded-b-lg bg-gradient-to-b from-gray-200 to-gray-100 border border-gray-300">
    <table class="min-w-full divide-y divide-gray-400">
      <slot />
    </table>
  </div>
</template>

<script>
export default {};
</script>
