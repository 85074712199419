<template>
  <div class="relative">
    <div v-if="open" class="fixed inset-0" @click="close" />
    <app-icon-button icon="more" aria-label="Toggle options" @click="toggle" />
    <transition enter-active-class="transition ease-out duration-150" enter-from-class="transform opacity-0 scale-75" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-150" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-75">
      <div v-if="open" class="z-10 absolute -ml-32 mt-1 w-40 rounded-md shadow-lg bg-white">
        <ul class="text-left">
          <li v-for="(option, index) in options" :key="index">
            <a v-tooltip:[tooltipData(option)]="tooltipText" href="#" :class="[option === 'Delete' && confirm ? ['text-red-700 hover:text-red-800 font-semibold'] : ['text-gray-600 hover:text-gray-700']]" class="block px-3 py-1.5 md:px-4 md:py-2 rounded-md text-xs md:text-sm font-normal capitalize border border-transparent hover:bg-gray-100 focus:outline-none focus:border-blue-400" @click.prevent="handleChoice(option)">
              <span :class="{'animate-pulse': option === 'Delete' && loading}">{{ option === 'Delete' && confirm ? 'Confirm' : option }}</span>
            </a>
            <div v-if="option === 'Delete' && count" class="px-3 py-1.5 md:px-4 md:py-2 bg-red-200 border border-red-200 rounded-b text-red-800 font-semibold text-xs leading-snug">
              <template v-if="entity == 'task'">
                Deleting this task will also delete its related time log entries.
              </template>
              <template v-else-if="entity == 'project'">
                Deleting this project will also delete its related tasks and time log entries.
              </template>
              <template v-else-if="entity == 'label'">
                Deleting this label will also delete it from all the tasks it has been added to.
              </template>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  props: {
    options: { type: Array, default: () => [] },
    entity: { type: String, default: "" },
    countUrl: { type: String, default: "" },
  },
  emits: ["click"],
  data() {
    return {
      loading: false,
      count: 0,
      confirm: false,
      open: false,
      tooltipText: `Click here to focus exclusively on this project and temporarily hide data from all other ones.`,
    };
  },
  computed: {
    ...mapState(["minimal"]),
  },
  methods: {
    tooltipData(option) {
      return {
        show: !this.minimal && option === "Focus mode",
        position: "top",
      };
    },
    close() {
      this.open = false;
      this.confirm = false;
      this.count = 0;
    },
    toggle() {
      this.open = !this.open;
    },
    emit(option) {
      this.close();
      this.$emit("click", option);
    },
    async handleChoice(option) {
      if (option === "Delete") {
        if (this.countUrl) {
          this.loading = true;
          const res = await U.api({ url: this.countUrl });
          this.loading = false;
          this.count = res.count;
        }
        if (this.confirm) {
          this.emit("Delete");
        }
        this.confirm = !this.confirm;
      } else {
        if (option === "Focus mode") {
          this.$store.commit("addTooltip", U.hash(this.tooltipText));
        }
        this.emit(option);
      }
    },
  },
};
</script>
