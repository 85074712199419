<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  created() {
    this.classes = "mb-1 md:mb-2";
  },
};
</script>
