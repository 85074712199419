<template>
  <p v-if="$slots.single !== undefined" class="text-xs md:text-sm text-gray-600">
    <slot name="single" />
  </p>
  <p v-else-if="$slots.subtle !== undefined" class="text-xs text-gray-500">
    <slot name="subtle" />
  </p>
  <p v-else class="text-xs md:text-sm text-gray-700 leading-snug">
    <span class="font-semibold"><slot name="first" /></span><br>
    <span class="text-xs text-gray-600"><slot name="second" /></span>
  </p>
</template>

<script>
export default {};
</script>
