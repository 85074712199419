<template>
  <div class="h-40 overflow-y-scroll pr-1.5">
    <div v-for="(option, index) in options" :key="index" :class="option.disabled ? 'text-gray-500' : 'text-gray-700 hover:border-gray-400'" class="flex items-center mb-1.5 bg-white w-full border border-gray-300 rounded py-1 px-2">
      <input :id="index" class="focus:ring-0 focus:border-blue-400" type="checkbox" :value="option.value" :disabled="option.disabled" :checked="modelValue.includes(option.value)" @change="change(option.value, $event.target.checked)">
      <label :for="index" class="flex-grow ml-2">{{ option.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
      default: undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      values: this.modelValue,
    };
  },
  methods: {
    change(value, checked) {
      if (checked) {
        this.values = [...this.values, value];
      } else {
        this.values = this.values.filter((v) => v !== value);
      }
      this.$emit("update:modelValue", this.values);
    },
  },
};
</script>
