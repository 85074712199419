import "./assets/css/tailwind.css";
import "./assets/css/main.css";
import { createApp } from "vue";
import { Util as U } from "@/util";
import router from "./router/index.js";
import App from "./App.vue";
import Badge from "./components/Badge.vue";
import Box from "./components/Box.vue";
import Button from "./components/Button.vue";
import Dropdown from "./components/Dropdown.vue";
import Feature from "./components/Feature.vue";
import Form from "./components/Form.vue";
import FormField from "./components/FormField.vue";
import FormAlert from "./components/FormAlert.vue";
import IconButton from "./components/IconButton.vue";
import Input from "./components/Input.vue";
import Label from "./components/Label.vue";
import Modal from "./components/Modal.vue";
import MultipleSelect from "./components/MultipleSelect.vue";
import Details from "./components/Details.vue";
import Select from "./components/Select.vue";
import Table from "./components/Table.vue";
import Tbody from "./components/Tbody.vue";
import Td from "./components/Td.vue";
import TdContent from "./components/TdContent.vue";
import TextArea from "./components/TextArea.vue";
import Th from "./components/Th.vue";
import Thead from "./components/Thead.vue";
import Timer from "./components/Timer.vue";
import TopMenuButton from "./components/TopMenuButton.vue";
import Tr from "./components/Tr.vue";
import Account from "./modals/Account.vue";
import Feedback from "./modals/Feedback.vue";
import store from "./store/index.js";

// Create app.
const app = createApp(App).use(store).use(router);

app.directive("tooltip", {
  mounted(el, binding) {
    U.mountTooltip(el, binding);
  },
  updated(el, binding) {
    U.updateTooltip(el, binding);
  },
});

// Register components.
app.component("AppAccount", Account);
app.component("AppBadge", Badge);
app.component("AppBox", Box);
app.component("AppButton", Button);
app.component("AppDropdown", Dropdown);
app.component("AppFeature", Feature);
app.component("AppForm", Form);
app.component("AppFormAlert", FormAlert);
app.component("AppFormField", FormField);
app.component("AppIconButton", IconButton);
app.component("AppInput", Input);
app.component("AppLabel", Label);
app.component("AppModal", Modal);
app.component("AppMultipleSelect", MultipleSelect);
app.component("AppDetails", Details);
app.component("AppSelect", Select);
app.component("AppFeedback", Feedback);
app.component("AppTable", Table);
app.component("AppTbody", Tbody);
app.component("AppTd", Td);
app.component("AppTdContent", TdContent);
app.component("AppTextArea", TextArea);
app.component("AppTh", Th);
app.component("AppThead", Thead);
app.component("AppTimer", Timer);
app.component("AppTopMenuButton", TopMenuButton);
app.component("AppTr", Tr);

// Mount the app.
app.mount("#app");
