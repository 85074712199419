
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      action: "",
      timerText: "",
      interval: 0,
      tooltipTextPlay: `Click to start timer for this task.`,
      tooltipTextPause: `Click here to pause the timer.`,
      tooltipTextLog: `Click to save current time to log.`,
      tooltipTextTrash: `Click to discard current time.`,
      tooltipTextClear: `Click here to close the timer.`,
    };
  },
  computed: {
    ...mapState([
      "minimal",
      "timerUuid",
      "initialSeconds",
      "passedSeconds",
      "startTime",
      "running",
    ]),
    disable() {
      if (this.item.done) {
        return true;
      } else if (this.timerUuid !== "" && this.timerUuid !== this.item.uuid) {
        return true;
      } else {
        return false;
      }
    },
    tooltipData() {
      return {
        show: !this.minimal && !this.disable,
        position: "top",
      };
    },
  },
  watch: {
    item({ done }) {
      if (done && this.running) {
        this.toggle();
      }
    },
  },
  created() {
    if (this.item.uuid === this.timerUuid) {
      this.timerText = U.timerFormat(this.passedSeconds);
      if (this.running) {
        this.interval = window.setInterval(() => {
          this.display();
        }, 1000);
        this.display();
      }
    }
  },
  beforeUnmount() {
    if (this.running) {
      window.clearInterval(this.interval);
    }
  },
  methods: {
    toggle() {
      this.$store.commit("addTooltip", U.hash(this.tooltipTextPlay));
      this.action = "";
      const initialSeconds =
        this.initialSeconds + this.passedSeconds - this.initialSeconds;
      this.$store.commit("state", {
        key: "initialSeconds",
        value: initialSeconds,
      });
      if (this.running) {
        this.$store.commit("addTooltip", U.hash(this.tooltipTextPause));
        window.clearInterval(this.interval);
      } else {
        if (this.timerUuid !== this.item.uuid) {
          this.$store.commit("state", {
            key: "timerUuid",
            value: this.item.uuid,
          });
        }
        this.interval = window.setInterval(() => {
          this.display();
        }, 1000);
      }
      this.$store.commit("state", { key: "running", value: !this.running });
      this.$store.commit("state", { key: "startTime", value: Date.now() });
      this.display();
    },
    display() {
      let passedSeconds = this.initialSeconds;
      passedSeconds += Math.round((Date.now() - this.startTime) / 1000);
      this.$store.commit("state", {
        key: "passedSeconds",
        value: passedSeconds,
      });
      this.timerText = U.timerFormat(this.passedSeconds);
    },
    log() {
      const itemUuid = this.item.uuid;
      const minutes = Math.ceil(this.passedSeconds / 60);
      const loggedFor = U.localDate();
      const createdAt = U.localTime();
      const obj = { itemUuid, minutes, loggedFor, createdAt };
      U.post("entry", null, obj);
      this.$store.commit("state", { key: "startTime", value: Date.now() });
      this.$store.commit("state", { key: "initialSeconds", value: 0 });
      this.$store.commit("addTooltip", U.hash(this.tooltipTextLog));
      this.action = "Saved to time log";
      this.display();
    },
    clear() {
      if (this.timerText == "0:00:00") {
        this.$store.commit("addTooltip", U.hash(this.tooltipTextClear));
      } else {
        this.$store.commit("addTooltip", U.hash(this.tooltipTextTrash));
      }
      this.$store.commit("state", { key: "startTime", value: Date.now() });
      this.$store.commit("state", { key: "initialSeconds", value: 0 });
      this.$store.commit("state", { key: "timerUuid", value: "" });
      const cleared = this.action != "";
      this.action = "Timer discarded";
      this.display();
      if (cleared) {
        this.timerText = "";
      }
    },
  },
});
