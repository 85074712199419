<template>
  <div id="feedback-modal">
    <transition name="fade">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <app-form-alert v-if="form.success" type="success" v-html="form.success" />
    </transition>
    <app-form v-if="!form.success" @submit.prevent="submitForm">
      <app-form-field>
        <app-label id="subject">
          Email subject
        </app-label>
        <app-input id="subject" v-model.trim="form.fields.subject" maxlength="45" />
      </app-form-field>
      <app-form-field>
        <app-label id="content">
          Email body
        </app-label>
        <app-text-area id="content" v-model.trim="form.fields.content" maxlength="1000" />
      </app-form-field>
      <app-button class="mt-3 transition-colors" :disabled="loading || invalidForm">
        Send email
      </app-button>
    </app-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Feedback",
  emits: ["close"],
  data: () => ({
    form: {
      fields: {
        content: "",
        subject: "",
      },
      success: "",
    },
  }),
  computed: {
    ...mapState(["loading"]),
    invalidForm() {
      const { content, subject } = this.form.fields;
      if (content === "" || subject === "") return true;
      return false;
    },
  },
  methods: {
    async submitForm() {
      if (this.invalidForm) return;
      const { content, subject } = this.form.fields;
      const body = { content, subject };
      const url = "api/email/support";
      await U.api({ url, method: "post", body });
      this.form.success = `Got it.<br><span class="font-normal">Thanks for your email, we'll get back to you as soon as we can. You can now close this window.</span>`;
    },
  },
};
</script>
