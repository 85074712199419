<template>
  <div :class="overlayClasses" class="z-50 table fixed top-0 left-0 w-full h-full">
    <div class="p-4 align-middle table-cell" @mousedown.self="$emit('close')">
      <div :class="modalClasses" class="relative mx-auto bg-gray-50 shadow-md border border-gray-400 rounded-lg">
        <h1 v-if="type === 'cover'" class="underline mt-8 mb-2 md:-mb-2 mx-6 md:mx-12 text-gray-700 font-cursive text-2xl leading-tight font-bold">
          <slot name="title" />
        </h1>
        <h1 v-else class="relative -top-4 mt-0.5 truncate max-w-5/6 inline-block mx-5 bg-white rounded px-2 py-1 border shadow uppercase tracking-wide text-gray-600 text-xs font-semibold">
          <slot name="title" />
        </h1>
        <div :class="contentClasses" class="mx-4 md:mx-10 -mt-2 md:mt-2 mb-4 md:mb-8">
          <slot />
        </div>
        <template v-if="type === 'cover'">
          <button class="absolute top-1 right-1 px-2 py-1 rounded-full text-center text-gray-500 hover:text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-400 text-xs" @click.prevent="$emit('close')">
            Close
          </button>
        </template>
        <template v-else>
          <button class="absolute top-1 right-1 px-1.5 py-1 rounded-full text-center text-gray-500 hover:text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-400" @click.prevent="$emit('close')">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
export default {
  props: {
    type: {
      type: String,
      default: "normal",
    },
  },
  emits: ["close"],
  computed: {
    overlayClasses() {
      return this.type == "cover" ? ["bg-cover"] : ["bg-modal"];
    },
    modalClasses() {
      const rv = [];
      if (this.type == "cover") {
        rv.push("max-w-4xl", "h-5/6");
      } else {
        rv.push("max-w-md");
      }
      return rv;
    },
    contentClasses() {
      return this.type == "cover" ? ["h-full", "pb-16"] : [];
    },
  },
  mounted() {
    this.$store.commit("state", { key: "modal", value: true });
    document.addEventListener("keydown", this.keyDown);
  },
  unmounted() {
    this.$store.commit("state", { key: "modal", value: false });
    document.removeEventListener("keydown", this.keyDown);
  },
  async created() {
    await nextTick();
    if (document.forms[0]) {
      document.forms[0].elements[0].focus();
    }
  },
  methods: {
    keyDown(event) {
      if (event.keyCode === 27) {
        this.$emit("close");
      }
    },
  },
};
</script>
