<template>
  <textarea rows="6" :class="classes" v-bind="$attrs" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Number, String],
      default: undefined,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    classes() {
      const classes = [
        "block",
        "w-full",
        "bg-white",
        "border",
        "border-gray-300",
        "rounded",
        "py-1",
        "px-2",
        "focus:ring-0",
        "placeholder-gray-400",
      ];
      if (this.$attrs.disabled) {
        classes.push("text-gray-500");
      } else {
        classes.push(
          "hover:border-gray-400",
          "text-gray-700",
          "focus:border-blue-400"
        );
      }
      return classes;
    },
  },
};
</script>
