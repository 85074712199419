import store from "@/store/index";

class Util {
  static scrollToLastAdded() {
    window.setTimeout(() => {
      const elm = document.getElementById("last-added");
      elm.scrollIntoView({ block: "end", inline: "nearest" });
    });
  }

  static dayOfWeek(day) {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return days[day - 1];
  }

  // add suffix to month day numbers
  static daySuffix(day) {
    if (day === 1 || day === 21 || day === 31) {
      return day + "st";
    } else if (day === 2 || day === 22) {
      return day + "nd";
    } else if (day === 3 || day === 23) {
      return day + "rd";
    } else {
      return day + "th";
    }
  }

  static addMissingData(series, categories) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const len = categories.length;
    const startYear = parseInt(categories[0].split(",")[1].trim());
    const startMonth = categories[0].split(",")[0];
    const endYear = parseInt(categories[len - 1].split(",")[1].trim());
    const endMonth = categories[len - 1].split(",")[0];
    let found = false;
    let pos = 0;
    for (let i = startYear; i <= endYear; i++) {
      for (let j = 0; j < months.length; j++) {
        if (i === startYear && months[j] === startMonth) {
          found = true;
        }
        if (found) {
          const txt = `${months[j]}, ${i}`;
          if (!categories.some((c) => c === txt)) {
            categories.splice(pos, 0, txt);
            series.forEach((s) => {
              s.data.splice(pos, 0, "0");
            });
          }
          pos++;
          if (i === endYear && months[j] === endMonth) {
            break;
          }
        }
      }
    }
  }

  static projectInFocus(item) {
    return (
      item.projectUuid === store.state.focusUuid || store.state.focusUuid === ""
    );
  }

  static doneBeforeToday(item) {
    return item.doneOn && item.doneOn !== Util.localDate();
  }

  static mountTooltip(el, binding) {
    if (!store.state.tooltips.includes(Util.hash(binding.value))) {
      if ((!binding.arg && !store.state.minimal) || binding.arg?.show) {
        el.setAttribute("data-tooltip", binding.value);
        el.classList.add(
          "relative",
          `tooltip-at-${binding.arg?.position || "top"}`
        );
      }
    }
  }

  static updateTooltip(el, binding) {
    if ((!binding.arg && !store.state.minimal) || binding.arg?.show) {
      if (!store.state.tooltips.includes(Util.hash(binding.value))) {
        el.setAttribute("data-tooltip", binding.value);
        el.classList.add("relative");
      }
    } else {
      el.removeAttribute("data-tooltip");
      el.classList.remove(
        "relative",
        `tooltip-at-${binding.arg?.position || "top"}`
      );
    }
  }

  static hash(str) {
    var hash = 0,
      i = 0,
      len = str.length;
    while (i < len) {
      hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
    }
    return hash + 2147483647 + 1;
  }

  static domainFromUrl(url) {
    const elm = document.createElement("a");
    elm.setAttribute("href", url);
    const reversed = elm.hostname.split("").reverse().join("");
    const matches = reversed.match(/[^\.]*?\.[^\.]*/g);
    const domain = matches[0].split("").reverse().join("");
    return domain;
  }

  static localTime() {
    var offset = new Date().getTimezoneOffset() * 60000;
    return new Date(Date.now() - offset).toISOString().substring(0, 19);
  }

  static localDate() {
    return Util.localTime().substring(0, 10);
  }

  static patch(name, arr, uuid, changes) {
    const body = Util.patchDoc(changes);
    Util.api({ url: Util.endpoint(name, uuid), method: "patch", body });
    return Util.patchLocal(arr, uuid, changes);
  }

  static patchDoc(changes) {
    const rv = [];
    for (const key in changes) {
      rv.push({ op: "replace", path: key, value: changes[key] });
    }
    return rv;
  }

  static patchLocal(arr, uuid, changes) {
    const index = arr.findIndex((x) => x.uuid === uuid);
    const newObj = { ...arr[index], ...changes };
    return [...arr.slice(0, index), newObj, ...arr.slice(index + 1)];
  }

  static post(name, arr, obj) {
    const newObj = Util.postObj(obj);
    const body = { [name]: newObj };
    Util.api({ url: Util.endpoint(name), method: "post", body });
    return arr ? Util.postLocal(arr, newObj) : newObj;
  }

  static postObj(obj) {
    const copy = { ...obj };
    if (!copy.uuid) {
      copy.uuid = Util.uuid();
    }
    return copy;
  }

  static postLocal(arr, newObj) {
    const newArr = arr.map((x) => ({ ...x, lastAdded: false }));
    newObj.lastAdded = true;
    newArr.push(newObj);
    return newArr;
  }

  static plainObject(obj) {
    const copy = { ...obj };
    for (const key in copy) {
      if (typeof copy[key] === "object") {
        delete copy[key];
      }
    }
    return copy;
  }

  static delete(name, arr, uuid) {
    Util.api({ url: Util.endpoint(name, uuid), method: "delete" });
    if (arr) {
      return Util.deleteLocal(arr, uuid);
    }
  }

  static deleteLocal(arr, uuid) {
    return arr.filter((x) => x.uuid !== uuid);
  }

  static endpoint(name, uuid) {
    let rv = "api/";
    rv += name === "entry" ? "entries" : `${name}s`;
    if (uuid) {
      rv += `/${uuid}`;
    }
    return rv.toLowerCase();
  }

  static baseUrl() {
    if (process.env.VUE_APP_LOCALHOST === "true") {
      return "http://localhost:5000";
    } else {
      return "";
    }
  }

  static uuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  static random() {
    return (Math.random().toString(36) + Date.now().toString(36)).substr(2);
  }

  static redirect(loggedIn) {
    return !loggedIn;
  }

  static timeFormat(val) {
    const hours = Math.floor(val / 60);
    let minutes = val % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${hours}h ${minutes}m`;
  }

  static timerFormat(val) {
    let hours = Math.floor((val / 60 / 60) % 60);
    let minutes = Math.floor((val / 60) % 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let seconds = val % 60;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    return `${hours}:${minutes}:${seconds}`;
  }

  static week(year, month, day) {
    function serial(days) {
      return 86400000 * days;
    }
    function dateserial(year, month, day) {
      return new Date(year, month - 1, day).valueOf();
    }
    function weekday(date) {
      return new Date(date).getDay() + 1;
    }
    function yearserial(date) {
      return new Date(date).getFullYear();
    }
    var date =
        year instanceof Date
          ? year.valueOf()
          : typeof year === "string"
          ? new Date(year).valueOf()
          : dateserial(year, month, day),
      date2 = dateserial(
        yearserial(date - serial(weekday(date - serial(1))) + serial(4)),
        1,
        3
      );
    return ~~((date - date2 + serial(weekday(date2) + 5)) / serial(7));
  }

  static types() {
    return ["Fixed", "Hourly", "Monthly", "None"];
  }

  static monthNames() {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }

  static currencies() {
    return [
      "AUD",
      "BGN",
      "BRL",
      "CAD",
      "CHF",
      "CNY",
      "CZK",
      "DKK",
      "EUR",
      "GBP",
      "HKD",
      "HRK",
      "HUF",
      "IDR",
      "ILS",
      "INR",
      "ISK",
      "JPY",
      "KRW",
      "MXN",
      "MYR",
      "NOK",
      "NZD",
      "PHP",
      "PLN",
      "RON",
      "RUB",
      "SEK",
      "SGD",
      "THB",
      "TRY",
      "USD",
      "ZAR",
    ];
  }

  static formatYearMonth(value) {
    const dt = new Date(value + "T00:00:00");
    let rv = this.monthNames()[dt.getMonth()].substr(0, 3);
    rv += ", " + dt.getFullYear();
    return rv;
  }

  static formatDate(value) {
    let rv = "";
    const now = new Date();
    const dt = new Date(value + "T00:00:00");
    rv = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(dt);
    if (now.getFullYear() !== dt.getFullYear()) {
      rv += `, ${dt.getFullYear()}`;
    }
    return rv;
  }

  static formatTime(value) {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(Date.parse(value));
  }

  static formatNumber(value) {
    return new Intl.NumberFormat("en-US", {}).format(value);
  }

  static formatMoney(value, currency) {
    let rv = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
    rv += ` ${currency}`;
    return rv;
  }

  static async getToken({ email, password }) {
    let token = "";
    try {
      const body = { email, password };
      const res = await Util.api({ url: "api/auth", method: "post", body });
      token = res.token;
    } catch (e) {
      return false;
    }
    if (token) {
      const tokenSet = Date.now();
      window.localStorage.setItem("token", token);
      window.localStorage.setItem("tokenSet", tokenSet);
      store.commit("state", { key: "token", value: token });
      store.commit("state", { key: "tokenSet", value: tokenSet });
      return true;
    } else {
      return false;
    }
  }

  static async apiAll(requests) {
    requests = requests.map((r) => {
      return Util.api({ ...r, load: false });
    });
    store.commit("state", { key: "loading", value: true });
    const rv = await Promise.all(requests);
    store.commit("state", { key: "loading", value: false });
    return rv;
  }

  static async api({ url, method = "get", body, file = false, load = true }) {
    const opts = {
      method: method.toUpperCase(),
      credentials: "include",
      headers: {},
    };
    const token = window.localStorage.getItem("token");
    if (token) {
      opts.headers["Authorization"] = "Bearer " + token;
    }
    if (body) {
      opts.body = body;
      if (!file) {
        opts.body = JSON.stringify(opts.body);
        opts.headers["Content-Type"] = "application/json";
      }
    }
    if (load) {
      store.commit("state", { key: "loading", value: true });
    }
    const res = await fetch(`${Util.baseUrl()}/${url}`, { ...opts });
    if (load) {
      store.commit("state", { key: "loading", value: false });
    }
    if (res.ok) {
      try {
        return await res.json();
      } catch (e) {}
    } else {
      if (res.status == 400) {
        const data = await res.json();
        if (data.hasOwnProperty("errors")) {
          const firstKey = Object.keys(data.errors)[0];
          return data.errors[firstKey][0];
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    }
  }
}

export { Util };
