<template>
  <button :class="classes" @click="$emit('click', $event)">
    <slot />
  </button>
</template>

<script>
export default {
  emits: ["click"],
  computed: {
    classes() {
      const classes = [
        "capitalize",
        "border",
        "border-gray-500",
        "px-1.5",
        "py-0.5",
        "text-xs",
        "bg-gray-200",
        "rounded",
        "shadow",
        "text-blue-900",
        "hover:border-gray-600",
        "hover:shadow-md",
        "focus:outline-none",
        "focus:border-blue-400",
      ];
      return classes;
    },
  },
};
</script>
