import { createStore } from "vuex";
import { Util as U } from "@/util";

export default createStore({
  state: {
    createdAt: "",
    tooltips: JSON.parse(localStorage.getItem("tooltips")) || [],
    focusUuid: "",
    loading: false,
    minimal: false,
    projectCount: 0,
    timerUuid: "",
    initialSeconds: 0,
    passedSeconds: 0,
    startTime: Date.now(),
    running: false,
    modal: false,
    token: localStorage.getItem("token"),
    tokenSet: localStorage.getItem("tokenSet"),
  },
  getters: {
    loggedIn(state) {
      if (!state.token || !state.tokenSet) return false;
      const daysSinceSet = (Date.now() - state.tokenSet) / 1000 / 60 / 60 / 24;
      return daysSinceSet < 30;
    },
  },
  mutations: {
    state(state, { key, value }) {
      state[key] = value;
    },
    addTooltip(state, tooltip) {
      if (!state.tooltips.includes(tooltip)) {
        state.tooltips = [...state.tooltips, tooltip];
        window.localStorage.setItem("tooltips", JSON.stringify(state.tooltips));
      }
    },
  },
  actions: {
    async initAccount({ commit }) {
      const { accounts } = await U.api({ url: "api/accounts" });
      commit("state", { key: "createdAt", value: accounts[0].createdAt });
      window.localStorage.setItem("aid", U.hash(accounts[0].id.toString()));
      const { count } = await U.api({ url: "api/projects/count" });
      commit("state", { key: "projectCount", value: count });
    },
  },
});
