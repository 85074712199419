<template>
  <label :class="classes" :for="id">
    <slot />
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    classes: "",
  }),
  created() {
    this.classes = "uppercase text-xs text-gray-500 whitespace-nowrap";
  },
});
</script>
