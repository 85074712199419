<template>
  <form novalidate @submit.prevent="$emit('submit', $event)">
    <slot />
  </form>
</template>

<script>
export default {
  emits: ["submit"],
};
</script>
