<template>
  <div>
    <template v-if="left">
      <div class="flex items-center bg-gradient-to-r from-gray-300 via-gray-200 rounded-lg">
        <div class="py-2 px-4 w-2/5 self-start">
          <p class="text-blue-800 text-sm md:text-base lg:text-lg p-4 mb-4">
            <span class="font-semibold">{{ feature.title }}</span><br>
            {{ feature.text }}
          </p>
        </div>
        <div class="w-3/5 pr-4">
          <img :src="`./features/${feature.key}.png`" class="min-w-full border border-gray-400 rounded-t-lg shadow-xl" style="transform: perspective(1000px) rotateX(5deg) rotateY(-10deg) scale3d(1, 1, 1);">
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex items-center bg-gradient-to-l from-gray-300 via-gray-200 rounded-lg">
        <div class="w-3/5 pl-4">
          <img :src="`./features/${feature.key}.png`" class="min-w-full border border-gray-400 rounded-t-lg shadow-xl" style="transform: perspective(1000px) rotateX(-5deg) rotateY(10deg) scale3d(1, 1, 1);">
        </div>
        <div class="py-2 px-4 w-2/5 self-start">
          <p class="text-blue-800 text-sm md:text-base lg:text-lg p-4 mb-4">
            <span class="font-semibold">{{ feature.title }}</span><br>
            {{ feature.text }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    feature: { type: Object, required: true },
    left: { type: Boolean, required: true },
  },
});
</script>
