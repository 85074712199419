<template>
  <div>
    <select class="bg-white w-full border border-gray-300 rounded py-0.5 px-1 md:py-1 md:px-2 text-sm md:text-base text-gray-700 focus:ring-0 focus:border-blue-400" :class="classes" v-bind="$attrs" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)">
      <option disabled value="" class="text-gray-500">Select</option>
      <option v-for="(option, index) in options" :key="index" :value="option.value || option">
        {{ option.name || option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Number, String],
      default: undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  computed: {
    classes() {
      const rv = [];
      if (!this.$attrs.disabled) {
        rv.push("hover:border-gray-400");
      }
      return rv;
    },
  },
};
</script>
