<template>
  <div>
    <template v-if="timerText">
      <div class="flex items-center pr-1.5 border border-gray-400 h-8 rounded-full text-gray-600">
        <div>
          <template v-if="running">
            <app-icon-button v-tooltip="tooltipTextPause" icon="pause" @click="toggle" />
          </template>
          <template v-else>
            <app-icon-button icon="play" @click="toggle" />
          </template>
        </div>
        <div class="text-sm ml-1.5 whitespace-nowrap select-none">
          <span v-if="timerText == '0:00:00' && action" class="px-1 py-0.5 align-top text-xs font-semibold border-b-2 bg-gradient-to-t" :class="action == 'Timer discarded' ? 'border-red-300 from-red-200 text-red-600' : 'border-green-300 from-green-200 text-green-600'">{{ action }}</span>
          <span v-else class="font-mono">{{ timerText }}</span>
        </div>
        <div class="text-sm border-l border-gray-400 ml-2 pl-1">
          <template v-if="running || passedSeconds === 0">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline align-text-top h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
            </svg>
          </template>
          <template v-else>
            <button v-tooltip="tooltipTextLog" class="focus:outline-none focus:text-gray-700 hover:text-gray-700" @click="log">
              <svg xmlns="http://www.w3.org/2000/svg" class="inline align-text-top h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
              </svg>
            </button>
          </template>
        </div>
        <div class="text-sm border-l border-gray-400 ml-1 pl-1">
          <template v-if="running">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline align-text-top h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </template>
          <template v-else>
            <template v-if="timerText == '0:00:00'">
              <button v-tooltip="tooltipTextClear" class="focus:outline-none focus:text-gray-700 hover:text-gray-700" @click="clear">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline align-text-top h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </template>
            <template v-else>
              <button v-tooltip="tooltipTextTrash" class="focus:outline-none focus:text-gray-700 hover:text-gray-700" @click="clear">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline align-text-top h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </template>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <app-icon-button v-tooltip:[tooltipData]="tooltipTextPlay" :disabled="disable" icon="play" @click="toggle" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      action: "",
      timerText: "",
      interval: 0,
      tooltipTextPlay: `Click to start timer for this task.`,
      tooltipTextPause: `Click here to pause the timer.`,
      tooltipTextLog: `Click to save current time to log.`,
      tooltipTextTrash: `Click to discard current time.`,
      tooltipTextClear: `Click here to close the timer.`,
    };
  },
  computed: {
    ...mapState([
      "minimal",
      "timerUuid",
      "initialSeconds",
      "passedSeconds",
      "startTime",
      "running",
    ]),
    disable() {
      if (this.item.done) {
        return true;
      } else if (this.timerUuid !== "" && this.timerUuid !== this.item.uuid) {
        return true;
      } else {
        return false;
      }
    },
    tooltipData() {
      return {
        show: !this.minimal && !this.disable,
        position: "top",
      };
    },
  },
  watch: {
    item({ done }) {
      if (done && this.running) {
        this.toggle();
      }
    },
  },
  created() {
    if (this.item.uuid === this.timerUuid) {
      this.timerText = U.timerFormat(this.passedSeconds);
      if (this.running) {
        this.interval = window.setInterval(() => {
          this.display();
        }, 1000);
        this.display();
      }
    }
  },
  beforeUnmount() {
    if (this.running) {
      window.clearInterval(this.interval);
    }
  },
  methods: {
    toggle() {
      this.$store.commit("addTooltip", U.hash(this.tooltipTextPlay));
      this.action = "";
      const initialSeconds =
        this.initialSeconds + this.passedSeconds - this.initialSeconds;
      this.$store.commit("state", {
        key: "initialSeconds",
        value: initialSeconds,
      });
      if (this.running) {
        this.$store.commit("addTooltip", U.hash(this.tooltipTextPause));
        window.clearInterval(this.interval);
      } else {
        if (this.timerUuid !== this.item.uuid) {
          this.$store.commit("state", {
            key: "timerUuid",
            value: this.item.uuid,
          });
        }
        this.interval = window.setInterval(() => {
          this.display();
        }, 1000);
      }
      this.$store.commit("state", { key: "running", value: !this.running });
      this.$store.commit("state", { key: "startTime", value: Date.now() });
      this.display();
    },
    display() {
      let passedSeconds = this.initialSeconds;
      passedSeconds += Math.round((Date.now() - this.startTime) / 1000);
      this.$store.commit("state", {
        key: "passedSeconds",
        value: passedSeconds,
      });
      this.timerText = U.timerFormat(this.passedSeconds);
    },
    log() {
      const itemUuid = this.item.uuid;
      const minutes = Math.ceil(this.passedSeconds / 60);
      const loggedFor = U.localDate();
      const createdAt = U.localTime();
      const obj = { itemUuid, minutes, loggedFor, createdAt };
      U.post("entry", null, obj);
      this.$store.commit("state", { key: "startTime", value: Date.now() });
      this.$store.commit("state", { key: "initialSeconds", value: 0 });
      this.$store.commit("addTooltip", U.hash(this.tooltipTextLog));
      this.action = "Saved to time log";
      this.display();
    },
    clear() {
      if (this.timerText == "0:00:00") {
        this.$store.commit("addTooltip", U.hash(this.tooltipTextClear));
      } else {
        this.$store.commit("addTooltip", U.hash(this.tooltipTextTrash));
      }
      this.$store.commit("state", { key: "startTime", value: Date.now() });
      this.$store.commit("state", { key: "initialSeconds", value: 0 });
      this.$store.commit("state", { key: "timerUuid", value: "" });
      const cleared = this.action != "";
      this.action = "Timer discarded";
      this.display();
      if (cleared) {
        this.timerText = "";
      }
    },
  },
});
</script>
