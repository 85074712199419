<template>
  <span class="inline-block relative">
    <app-icon-button v-tooltip="tooltipText" icon="notes" aria-label="Open notepad" @click="openModal" />
    <span v-if="item.details" class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-gradient-to-r from-blue-400" />
  </span>
  <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
    <app-modal v-if="showModal" type="cover" @close="closeModal">
      <template #title>
        {{ item.title }}
      </template>
      <form class="h-full pb-10">
        <app-text-area v-model="details" :placeholder="!minimal ? 'Write down more details about this task here...' : null" class="lines resize-none border-none font-cursive text-2xl h-full leading-normal" />
      </form>
    </app-modal>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import { defineComponent } from "vue";
import { Util as U } from "@/util";
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ["save"],
  data() {
    return {
      activeElement: null,
      details: "",
      showModal: false,
      tooltipText: `Click here to open the notepad for this task where you can write down more details about it.`,
    };
  },
  computed: {
    ...mapState(["minimal"]),
  },
  methods: {
    openModal() {
      this.activeElement = document.activeElement;
      this.showModal = true;
      this.details = this.item.details || "";
      this.$store.commit("addTooltip", U.hash(this.tooltipText));
      this.interval = window.setInterval(() => {
        this.save();
      }, 10000);
    },
    closeModal() {
      this.activeElement.focus();
      this.showModal = false;
      window.clearInterval(this.interval);
      this.save();
    },
    save() {
      if (this.details !== this.item.details) {
        this.$emit("save", this.details);
      }
    },
  },
});
</script>
