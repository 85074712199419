<template>
  <div class="mt-4 mx-auto" :class="width === 'small' ? 'max-w-sm' : 'max-w-full'">
    <div class="bg-gray-50 shadow-md border border-gray-400" :class="{'min-h-page': width === 'large', 'rounded-none md:rounded-lg': minimal, 'rounded-lg': !minimal}">
      <h1 class="relative -top-4 mt-0.5 truncate max-w-5/6 mx-5 bg-white rounded px-2 py-1 border border-gray-400 shadow uppercase tracking-wide text-gray-600 text-xs font-semibold" :class="width === 'small' ? 'inline-block' : 'hidden md:inline-block'">
        <slot name="legend" />
      </h1>
      <div class="relative px-5 md:px-10 pb-8" :class="width === 'small' ? 'pt-0.5' : 'pt-4 md:pt-0.5'">
        <transition enter-active-class="transition-opacity duration-300" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-300" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <svg v-if="loading || recentlyChanged" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-xs absolute right-1 text-gray-600 animate-spin" :class="width === 'small' ? '-top-8' : 'top-1 md:-top-8'" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
        </transition>
        <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <div v-if="width === 'small' || loaded">
            <p v-if="$slots.title !== undefined" :class="{'invisible': minimal}" class="mb-2 text-xl md:text-2xl text-gray-700 leading-tight">
              <slot name="title" />
            </p>
            <p v-if="$slots.intro !== undefined" :class="{'invisible': minimal}" class="mb-4 md:mb-6 text-sm md:text-base text-gray-700 leading-snug">
              <slot name="intro" />
            </p>
            <slot />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  inheritAttrs: false,
  props: {
    width: {
      type: String,
      default: "small",
    },
  },
  data: () => ({
    recentlyChanged: false,
    loaded: false,
  }),
  computed: {
    ...mapState(["loading", "minimal"]),
  },
  watch: {
    loading() {
      this.loaded = true;
      this.recentlyChanged = true;
      if (this.timeout) {
        clearInterval(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.recentlyChanged = false;
      }, 250);
    },
  },
};
</script>
