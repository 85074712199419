<template>
  <div id="home-page" class="js-home">
    <div class="hero-bg relative bg-gradient-to-r from-orange-500 to-red-600">
      <div class="container max-w-screen-lg mx-auto shadow-hero-box pb-64 pt-2 md:pt-0">
        <div class="-mt-1 py-4 px-4 flex items-center">
          <div class="flex-grow text-white text-opacity-25 hidden md:block">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="flex-grow md:flex-none">
            <ul class="flex justify-center md:justify-start font-montserrat font-bold tracking-wider uppercase text-xs">
              <li>
                <a href="#" class="pb-0.5 px-2 mx-2 text-red-100 border-b-4 border-white border-opacity-20 shadow-hero-small" @click.prevent="scrollTo('js-home')">Home</a>
              </li>
              <li>
                <a href="#" class="pb-0.5 px-2 mx-2 text-red-300 hover:text-red-200 transition shadow-hero-small" @click.prevent="scrollTo('js-overview')">Overview</a>
              </li>
              <li>
                <a href="#" class="pb-0.5 px-2 mx-2 text-red-300 hover:text-red-200 transition shadow-hero-small hidden sm:inline" @click.prevent="scrollTo('js-features')">Features</a>
              </li>
              <li>
                <router-link to="/login" class="pb-0.5 px-2 mx-2 text-red-300 hover:text-red-200 transition shadow-hero-small">Login</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-white text-center font-montserrat">
          <h1 class="font-bold font-lora shadow-hero text-5xl sm:text-6xl md:text-7xl lg:text-8xl">Solo Workflow</h1>
          <h2 class="px-4 text-white text-center shadow-hero-small text-sm sm:text-base md:text-lg lg:text-xl">Project management, time tracking and invoicing for freelancers.</h2>
          <router-link to="/signup" class="tracking-wider uppercase inline-block px-8 py-4 bg-white font-bold rounded-lg focus:outline-none text-red-700 hover:text-red-800 shadow hover:opacity-95 transition text-xs md:text-sm mt-6 sm:mt-8 md:mt-10 lg:mt-12">
            Create your account
            <svg xmlns="http://www.w3.org/2000/svg" class="inline align-top ml-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
            </svg>
          </router-link>
          <p class="mt-2 text-xs text-red-200 font-extralight">It's free, but <a href="https://www.buymeacoffee.com/perdjurner" class="underline hover:no-underline" rel="noopener" target="_blank">some coffee</a> would be nice!</p>
        </div>
      </div>
      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".3" class="shape-fill" />
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".6" class="shape-fill" />
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill" />
        </svg>
      </div>
    </div>
    <div class="my-12 sm:my-14 md:my-16 lg:my-20 js-overview font-montserrat">
      <div class="container max-w-screen-lg mx-auto">
        <div class="sm:mt-14 sm:flex sm:justify-around">
          <div class="mb-6 sm:mb-0 sm:w-1/2 px-6 md:px-8">
            <h2 class="mb-1 lg:mb-2 text-xl md:text-2xl lg:text-4xl font-bold text-orange-600">
              <span class="mr-0.5 md:mr-1.5 lg:mr-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline align-top md:align-text-top h-8 w-8 lg:h-12 lg:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
              </span>
              Get things done
            </h2>
            <p class="text-sm md:text-base lg:text-lg font-medium text-gray-700 pl-10 md:pl-12 lg:pl-16 leading-relaxed">
              The key to getting things done is to write everything down, divide into manageable pieces, and then focus on one thing at a time. Our <span class="glow">tailor-made to-do list</span> helps you become more organized and effective.
            </p>
          </div>
          <div class="mb-6 sm:mb-0 sm:w-1/2 px-6 md:px-8">
            <h2 class="mb-1 lg:mb-2 text-xl md:text-2xl lg:text-4xl font-bold text-orange-600">
              <span class="mr-0.5 md:mr-1.5 lg:mr-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline align-top md:align-text-top h-8 w-8 lg:h-12 lg:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
              Track time worked
            </h2>
            <p class="text-sm md:text-base lg:text-lg font-medium text-gray-700 pl-10 md:pl-12 lg:pl-16 leading-relaxed">
              Time is money, you know that. This is why it's so important to keep track of how much of it you spend, and on what. We provide <span class="glow">timers and time logging</span> features to help you as you work on your tasks.
            </p>
          </div>
        </div>
        <div class="sm:mt-14 sm:flex sm:justify-around">
          <div class="mb-6 sm:mb-0 sm:w-1/2 px-6 md:px-8">
            <h2 class="mb-1 lg:mb-2 text-xl md:text-2xl lg:text-4xl font-bold text-orange-600">
              <span class="mr-0.5 md:mr-1.5 lg:mr-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline align-top md:align-text-top h-8 w-8 lg:h-12 lg:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
              Invoice your clients
            </h2>
            <p class="text-sm md:text-base lg:text-lg font-medium text-gray-700 pl-10 md:pl-12 lg:pl-16 leading-relaxed">
              When it's time to invoice your clients for the time you have spent working for them, we provide you with professional <span class="glow">invoices in PDF format</span> that you can download and email to them.
            </p>
          </div>
          <div class="mb-6 sm:mb-0 sm:w-1/2 px-6 md:px-8">
            <h2 class="mb-1 lg:mb-2 text-xl md:text-2xl lg:text-4xl font-bold text-orange-600">
              <span class="mr-0.5 md:mr-1.5 lg:mr-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline align-top md:align-text-top h-8 w-8 lg:h-12 lg:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
              </span>
              Reach your goals
            </h2>
            <p class="text-sm md:text-base lg:text-lg font-medium text-gray-700 pl-10 md:pl-12 lg:pl-16 leading-relaxed">
              How do you know if you're improving if you can't measure it? We provide you with <span class="glow">relevant statistics</span> so that you can see if you're getting closer to reaching your goals.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden sm:block py-14 md:py-16 lg:py-20 js-features font-montserrat bg-gray-200 border-t border-gray-400">
      <div class="container max-w-screen-lg mx-auto">
        <div class="mb-16">
          <h2 class="text-center text-xl md:text-2xl lg:text-4xl font-bold text-blue-700">Let's dive into the details.</h2>
          <p class="text-center text-sm md:text-base lg:text-lg font-medium text-blue-800 leading-relaxed">Here's a list of the features available to you when using Solo Workflow.</p>
        </div>
        <template v-for="(feature, i) in features" :key="feature.key">
          <app-feature class="mb-16 lg:mb-32" :feature="feature" :left="i % 2 == 0" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      features: [
        {
          key: "tasks",
          title: "Create, prioritize and complete tasks",
          text: 'Add the tasks currently on your plate to the list. Prioritize them by using drag & drop. Get that nice feeling of accomplishment when you see the green "done" tick for a task, before it\'s automatically archived the next day.',
        },
        {
          key: "recurring",
          title: "Set recurring tasks",
          text: "Make sure you never forget the things you do on a regular basis. Add tasks that happen either monthly or weekly and, like magic, they will appear on your task list accordingly.",
        },
        {
          key: "deadline",
          title: "Schedule tasks with a deadline",
          text: "If you want a task to appear on your task list on a specific date, you can set it with a deadline. Once it has appeared on the list, it will also be highlighted to make sure you see its importance.",
        },
        {
          key: "labels",
          title: "Apply labels to tasks",
          text: "You can create any label you want to and apply them to tasks. This makes them easier to spot in task lists. This feature is probably most useful when you're filtering your backlog to decide what to work on next.",
        },
        {
          key: "pdf",
          title: "Download PDF version of invoice",
          text: "Once you have created an invoice you can generate a PDF version of it at any time. The PDF file contains all the information that you typically need on a standard invoice. Simply download and pass it along to your clients so they can pay you.",
        },
        {
          key: "details",
          title: "Open the details window",
          text: "The details window is probably where you'll spend the most time. Its purpose is to be an area where you write down notes as you're working on a task. It very intentionally takes up the entire screen so nothing can distract you while you're in there.",
        },
        {
          key: "clone",
          title: "Clone an invoice",
          text: "To save time you can clone an existing invoice instead of creating a new one from scratch. Just edit the details that differ from the source invoice, like the invoice number for example.",
        },
        {
          key: "calculate",
          title: "Calculate amount for an invoice",
          text: "You can calculate the amount to put on an invoice from your time logged across multiple projects. The only requirement is that the projects have the same billing type (e.g. hourly).",
        },
      ],
    };
  },
  beforeMount() {
    document.body.classList.add("home");
  },
  beforeUnmount() {
    document.body.classList.remove("home");
  },
  methods: {
    scrollTo(cls) {
      const elm = document.querySelector(`.${cls}`);
      const top = elm.getBoundingClientRect().top + window.scrollY;
      window.scroll({ top, behavior: "smooth" });
    },
  },
};
</script>
