<template>
  <div id="account-modal">
    <transition name="fade">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <app-form-alert v-if="form.error || form.success" :type="form.success ? 'success' : 'error'" v-html="form.success ? form.success : form.error" />
    </transition>
    <app-form v-if="!form.success" @submit.prevent="submitForm">
      <app-form-field>
        <app-label id="name">
          Name
        </app-label>
        <app-input id="name" v-model.trim="form.fields.name" maxlength="45" />
      </app-form-field>
      <app-form-field>
        <app-label id="email">
          Email
        </app-label>
        <app-input id="email" v-model.trim="form.fields.email" maxlength="45" />
      </app-form-field>
      <app-form-field>
        <app-label id="address">
          Address
        </app-label>
        <app-text-area id="address" v-model.trim="form.fields.address" maxlength="250" />
      </app-form-field>
      <app-form-field>
        <app-label id="currency">
          Home currency
        </app-label>
        <app-select id="currency" v-model="form.fields.currency" :options="currencies" />
        <p class="mt-1 text-xs text-gray-500 leading-snug">
          Set this to the currency that you want to see reports in. You can still create invoices in any currency that you want to, but when shown in reports they will be converted to your home currency.
        </p>
      </app-form-field>
      <app-button class="mt-3 transition-colors" :disabled="loading || invalidForm">
        Update
      </app-button>
    </app-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Account",
  emits: ["close"],
  data: () => ({
    accountId: 0,
    currencies: [],
    form: {
      fields: {
        address: "",
        currency: "",
        email: "",
        name: "",
      },
      error: "",
      success: "",
    },
  }),
  computed: {
    ...mapState(["loading"]),
    invalidForm() {
      const { currency, email, name } = this.form.fields;
      if (currency === "" || email === "" || name === "") return true;
      return false;
    },
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      const { accounts } = await U.api({ url: "api/accounts" });
      const account = accounts[0];
      this.accountId = account.id;
      this.form.fields.address = account.address;
      this.form.fields.currency = account.currency;
      this.form.fields.email = account.email;
      this.form.fields.name = account.name;
      this.form.fields.currency = account.currency;
      this.currencies = U.currencies();
    },
    async submitForm() {
      if (this.invalidForm) return;
      const { address, currency, email, name } = this.form.fields;
      if (!email.includes("@")) {
        this.form.error = `Oops, something went wrong.<br><span class="font-normal">The email address is not formatted correctly.</span>`;
      } else {
        const body = [
          { op: "replace", path: "address", value: address },
          { op: "replace", path: "currency", value: currency },
          { op: "replace", path: "email", value: email },
          { op: "replace", path: "name", value: name },
        ];
        const url = `api/accounts/${this.accountId}`;
        const error = await U.api({ url, method: "patch", body });
        if (error) {
          this.form.error = `Oops, something went wrong.<br><span class="font-normal">${error}</span>`;
        } else {
          this.form.success = `Update made.<br><span class="font-normal">Your account information was successfully updated. You can now close this window.</span>`;
        }
      }
    },
  },
};
</script>
