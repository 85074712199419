<template>
  <button :class="classes" @click="$emit('click', $event)">
    <slot />
  </button>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  emits: ["click"],
  computed: {
    ...mapState(["minimal"]),
    classes() {
      const classes = [
        "capitalize",
        "border",
        "border-gray-400",
        "focus:outline-none",
        "focus:border-blue-400",
        "whitespace-nowrap",
      ];
      if (this.type === "add") {
        classes.push(
          "bg-gradient-to-r",
          "from-gray-200",
          "to-gray-300",
          "font-semibold",
          "rounded-full"
        );
        if (this.minimal) {
          classes.push("text-xs mt-5 md:mt-3 px-2 py-1 md:px-2 md:py-1");
        } else {
          classes.push("text-sm md:text-lg px-4 py-2 md:px-6 md:py-3");
        }
      } else {
        classes.push(
          "px-3",
          "py-1.5",
          "md:px-4",
          "md:py-2",
          "bg-gray-300",
          "text-sm",
          "md:text-base",
          "font-semibold",
          "rounded"
        );
      }
      if (this.$attrs.disabled) {
        classes.push("text-gray-500", "cursor-default");
      } else {
        classes.push("text-gray-700", "hover:border-gray-500", "hover:shadow");
      }
      return classes;
    },
  },
};
</script>
