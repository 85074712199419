import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const components = [
  { name: "Home", cmp: Home },
  { name: "Archive", cmp: () => import("../views/Archive.vue") },
  { name: "Backlog", cmp: () => import("../views/Backlog.vue") },
  { name: "Reports", cmp: () => import("../views/Reports.vue") },
  { name: "Invoices", cmp: () => import("../views/Invoices.vue") },
  { name: "Labels", cmp: () => import("../views/Labels.vue") },
  { name: "Login", cmp: () => import("../views/Login.vue") },
  { name: "Password", cmp: () => import("../views/Password.vue") },
  { name: "Projects", cmp: () => import("../views/Projects.vue") },
  { name: "Reset", cmp: () => import("../views/Reset.vue") },
  { name: "Signup", cmp: () => import("../views/Signup.vue") },
  { name: "Tasks", cmp: () => import("../views/Tasks.vue") },
  { name: "Test", cmp: () => import("../views/Test.vue") },
  { name: "TimeLog", cmp: () => import("../views/TimeLog.vue") },
];
const routes = components.map((c) => ({
  component: c.cmp,
  name: c.name,
  path: `/${c.name !== "Home" ? c.name.toLowerCase().replace(/ /g, "") : ""}`,
}));

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
