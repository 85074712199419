<template>
  <div
    :class="{ 'mx-auto container max-w-screen-lg px-2': $route.path !== '/' }"
  >
    <div v-if="showLoggedInContent" class="mt-4 text-right">
      <app-top-menu-button
        v-if="!minimal"
        class="ml-2"
        @click="openModal('Feedback')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="hidden md:inline align-text-top h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
          />
        </svg>
        <span class="md:ml-1 md:mr-0.5">Feedback</span>
      </app-top-menu-button>
      <app-top-menu-button
        v-if="!minimal"
        class="ml-2"
        @click="openModal('Account')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="hidden md:inline align-text-top h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span class="md:ml-1 md:mr-0.5">Account</span>
      </app-top-menu-button>
      <app-top-menu-button
        v-if="loggedIn && !minimal"
        class="ml-2"
        @click="logOut"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="hidden md:inline align-text-top h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
        <span class="md:ml-1 md:mr-0.5">Log out</span>
      </app-top-menu-button>
      <template v-if="minimal">
        <app-top-menu-button
          class="ml-2"
          aria-label="Toggle minimal mode"
          @click="expandLayout"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline align-text-top h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
            />
          </svg>
        </app-top-menu-button>
      </template>
      <template v-else>
        <app-top-menu-button
          v-tooltip:[tooltipData]="tooltipText"
          class="ml-2"
          aria-label="Toggle minimal mode"
          @click="compressLayout"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline align-text-top h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
        </app-top-menu-button>
      </template>
    </div>
    <transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <app-modal v-if="showModal" @close="closeModal">
        <template #title>
          {{ showModal }}
        </template>
        <app-account v-if="showModal === 'account'" @close="closeModal" />
        <app-feedback v-if="showModal === 'feedback'" @close="closeModal" />
      </app-modal>
    </transition>
    <div :class="[showLoggedInContent ? ['flex'] : []]">
      <div v-if="showLoggedInContent">
        <ul
          class="mt-8 mr-2 flex-shrink-0 divide-y divide-gray-400"
          :class="{ 'md:mr-8': !minimal }"
        >
          <template v-for="(o, i) in cOptions" :key="i">
            <li>
              <component
                :is="o.active ? 'router-link' : 'span'"
                :to="o.path"
                class="block whitespace-nowrap text-xl font-semibold capitalize py-4"
                :class="[
                  o.active
                    ? [
                      o.current
                        ? 'text-orange-600'
                        : 'text-blue-800 hover:text-orange-600',
                    ]
                    : ['text-gray-500'],
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline align-text-top h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    :d="o.svg"
                  />
                </svg>
                <span v-if="!minimal" class="hidden md:inline ml-2">
                  {{ o.name }}
                </span>
              </component>
            </li>
          </template>
        </ul>
      </div>
      <div :class="[showLoggedInContent ? ['flex-grow pb-10'] : []]">
        <router-view />
      </div>
    </div>
  </div>
  <p
    v-if="showLoggedInContent"
    class="fixed left-1 bottom-1 text-gray-700 text-xs font-thin"
  >
    {{ version }}
  </p>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  data() {
    return {
      showModal: "",
      tooltipText: `Switch to minimal mode, which makes the content area wider and hides some text & tooltips.`,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    ...mapState(["focusUuid", "loading", "minimal", "projectCount"]),
    tooltipData() {
      return { show: !this.minimal, position: "bottom" };
    },
    showLoggedInContent() {
      return (
        this.loggedIn &&
        this.$route.path !== "/" &&
        this.$route.path !== "/login" &&
        this.$route.path !== "/signup" &&
        this.$route.path !== "/reset" &&
        this.$route.path !== "/password"
      );
    },
    invalidForm() {
      return false;
    },
    cOptions() {
      return this.options
        .filter(
          (x) =>
            this.focusUuid == "" ||
            (x.name !== "Reports" && x.name !== "Invoices")
        )
        .map((x) => {
          x.path = x.name.toLowerCase().replace(/ /g, "");
          x.current =
            this.$route.path === `/${x.path}` ||
            x.sub.includes(this.$route.path);
          x.active = x.path === "projects" || this.projectCount ? true : false;
          return x;
        });
    },
  },
  created() {
    this.version = "4.0.3";
    if (this.loggedIn) {
      this.$store.dispatch("initAccount");
    }
    if (process.env.VUE_APP_LOCALHOST === "true") {
      document.title = "Dev";
    }
    this.options = [
      {
        name: "Projects",
        svg:
          "M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z",
        sub: [],
      },
      {
        name: "Tasks",
        svg:
          "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4",
        sub: ["/backlog", "/archive", "/labels"],
      },
      {
        name: "Time log",
        svg: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
        sub: [],
      },
      {
        name: "Invoices",
        svg:
          "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
        sub: [],
      },
      {
        name: "Reports",
        svg:
          "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
        sub: [],
      },
    ];
  },
  methods: {
    expandLayout() {
      this.$store.commit("state", { key: "minimal", value: false });
    },
    compressLayout() {
      this.$store.commit("state", { key: "minimal", value: true });
      this.$store.commit("addTooltip", U.hash(this.tooltipText));
    },
    logOut() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("tokenSet");
      this.$store.commit("state", { key: "token", value: null });
      this.$store.commit("state", { key: "tokenSet", value: null });
      this.$router.push("/");
    },
    openModal(name) {
      this.showModal = name.toLowerCase();
    },
    closeModal() {
      this.showModal = "";
    },
  },
};
</script>
