
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    color: { type: String, required: true },
    description: { type: String, default: "" },
  },
  computed: {
    title(): string {
      return this.description ? `${this.description}.` : "";
    },
    classes(): string {
      const colors: { [key: string]: string } = {
        gray: `border-gray-400 from-gray-300 to-gray-200 text-gray-700`,
        red: `border-red-400 from-red-300 to-red-200 text-red-700`,
        orange: `border-orange-400 from-orange-300 to-orange-200 text-orange-700`,
        yellow: `border-yellow-400 from-yellow-300 to-yellow-200 text-yellow-700`,
        teal: `border-teal-400 from-teal-300 to-teal-200 text-teal-700`,
        green: `border-green-400 from-green-300 to-green-200 text-green-700`,
        blue: `border-blue-400 from-blue-300 to-blue-200 text-blue-700`,
        indigo: `border-indigo-400 from-indigo-300 to-indigo-200 text-indigo-700`,
        purple: `border-purple-400 from-purple-300 to-purple-200 text-purple-700`,
        pink: `border-pink-400 from-pink-300 to-pink-200 text-pink-700`,
      };
      return colors[this.color.toLowerCase()];
    },
  },
});
