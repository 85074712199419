
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  computed: {
    classes() {
      const rv = [];
      if (this.disabled) {
        rv.push("text-gray-500 cursor-default");
      } else {
        rv.push("text-gray-600 hover:border-gray-500 hover:shadow");
      }
      return rv;
    },
  },
});
